/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "Digital";
  src: local("Digital"), url(./assets/fonts/DS-DIGI.TTF) format("truetype");
}

:root{
  touch-action: pan-x, pan-y;
  height: 100%;
  --vimo-color: #13213c;
  --default-color: #00708d;
  --exit-color: #334144;
}

html,body {overscroll-behavior: none;}

ion-button.exit{
  &::part(native){
    background-color: var(--exit-color);
  }
}

.ion-page {
    background-color:var(--default-color) !important;
    background-image: url(./assets/img/bg.png);
    background-repeat: no-repeat;
    // padding:20px;
    overflow: scroll !important;
    background-attachment: fixed;
    background-size: unset;
}

ion-content {
    touch-action: manipulation;
    background: rgba(255, 255, 255, 0.2) ;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    > div {
        margin:0 20px;
    }
} 
.fondo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }

input[type='text'], input[type='password'], input[type='email'] {
    box-shadow: 0 0 4px #c3c3c3;
    border: 0;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 20px;
    border-radius: 5px;
}

div.background{
  background: linear-gradient(0deg, transparent 0%, #13213c 20%);
  width: 100%;
  height: 150px;
  position: absolute;
  z-index: 0;
  margin: 0;
}

.item-background-color{
  --ion-item-background:transparent;
}

#logo {
  margin-top:30px;
  margin-bottom:30px;
  z-index: 123;
  position: relative;
}
#inicio ion-button {
    margin:10px 0 10px 0;
    float:left;
    width:100%;
}
ion-content#login {
  align-items: center;
}
.error {
  transform: perspective(50vh) rotatex(0deg) rotate(0deg);
  color: red;
  font-weight: bold;
  background: #ffdcdc;
  padding: 10px;
  border: 1px dashed;
  margin: 0 0 10px 0 !important;
  font-size: 20px !important;
}


  .buttons {display: flex;justify-content: space-between;}
  .botonera {
    display: flex;
    justify-content: space-between;
    position:absolute;
    bottom:0;
    width: 100%;
    left: 0;
    > ion-button {
      border-radius: 100px;
      height: 90px;
      width: 90px;
      background: #fff;
      margin: 10px !important;
      overflow: hidden;
      box-shadow: 0 0 10px rgb(0 0 0 / 15%);
      button {
        padding:0;
      }

      svg {
        fill: #6D85BF;
        stroke: #6D85BF;
      }
    }
  }
  ion-button.startButton {
    font-size: 20px;
    box-shadow: 0 0 60px #000;
    border-radius: 13px;
    width: 60%;
    top: 60%;
    left: calc(50% - 60% / 2);
    position: absolute;
  }
  .bigicon {
    height: 200px;
  }

h1.bignumber {
  font-size: 2.5rem;
  color: #00708d;
}
.fondo  p {
  font-size: calc(2vh + 2vw);
  margin:20px;
}

.retest {
  margin: 10px 0;
}
.stopwatch {    margin: 30px 0 0 0;}
#resultados .stopwatch {  
  font-size: 70px;    margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


  .encuesta {
    table {
      width:90%;
      margin:5%;
      thead th {
        padding:5px;
        background: #00708d;
        color: #fff;
        text-align: left;
      }
      tbody {
        tr:nth-child(even){
          td {
            background:#ddd;
          }
        }
          td {
            padding:5px;
            text-align: left;
          }
      }
    }
  }

  .permission-modal{
    &::part(content){
      width: 90%;
      height: 90%;
      border-radius: 6px;
      box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
      text-align: center;
    }
}

ion-content{
  &::part(background){
    background-color: linear-gradient();
    // height: 100vh;
    // border: 3px solid red
  }
}

@media (min-width: 750px){

  .botonera{
    ion-button{
      width: calc(90px * 2);
      height: calc(90px * 2);
      img{
        transform: scale(1.5);
      }
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nodrag{
  user-drag: none;
  -webkit-user-drag: none;
}